/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
const SubFooterContainer =  styled.footer` 

  width:100%;
  padding:40px;
  border-top:1px solid black;
  text-align:center;
    .art-money {

      line-height:36px;
    }
  @media (min-width: 600px) {
    padding:20px;
  &>div {
    text-align:left;
    display:flex;
    margin-left:auto;
    justify-content:space-between;
    margin-right:auto;
    padding-top:40px;
    margin-top:40px;
    max-width: 1400px;
    width:100%;
  }

  }
  
  ul {
    margin-bottom:0;
    li {
      display:inline-block;
      margin-right:20px;
      margin-bottom:0;
      cursor: pointer;
      padding: 0px 20px;
    }
  }
`     


const FooterContainer =  styled.footer` 
  border-top:1px solid black;
  width:100%;
  padding:40px;
  text-align:center;
    .art-money {

      line-height:20px;
    }
  @media (min-width: 600px) {
    padding:0;
  &>div {
    p {
      margin-left:20px;
    }
    text-align:left;
    display:flex;
    margin-left:auto;
    justify-content:space-between;
    margin-right:auto;
    padding-top:40px;
    margin-top:40px;
    margin-bottom:40px;
    max-width: 1400px;
    width:100%;
  }
  .art-money {
    display:flex;
    @media (max-width: 600px) { 
      margin-top:20px;
    }
    
      text-align:right;
      margin-right:20px;
  }
  }
  
  ul {
    li {
      list-style-type:none;
      margin-right:20px;
      a {
      border-bottom: 3px solid rgb(60, 230, 200);
      }
      cursor: pointer;
      padding: 6px 0px;
    }
  }
`     
    
function Footer() {
  
  return (
  <div>
    
  <div className="newsletter" style={{
      
    borderTop:"1px solid black",
    width:"100%"
  }}>
      <p style={{
          textAlign:"center",
          padding:"60px 20px 0px 20px",
          fontSize:"18px",
                maxWidth: "1400px",
                marginLeft:"auto",
                      marginRight:"auto",
      }}>If you wish to subscribe to our newsletter, fill in the form below</p>
    <div style={{
      display:"flex",
      marginLeft:"auto",
      justifyContent:"center",
      marginRight:"auto",
      padding:"36px 20px",
      marginTop:"0px",
      maxWidth: "1400px",
      width:"100%"
    }}>

    <input type="text" placeholder="Your e-mail address"></input><br />
    <button type="Submit">Submit</button>
  </div>
  </div>  
  <FooterContainer>
    <div>

    <div>
    <ul>
    <li><a href="https://www.facebook.com/franceskeevilart">Follow us on Facebook &#x27F6;</a ></li>
    <li><a href="http://www.instagram.com/franceskeevil">Follow us on Instagram &#x27F6;</a ></li>
    <li><Link to="#">frances@franceskeevil.com.au</Link ></li>
    <li>+61 411 821 550</li>
    </ul>

        </div>

        <div className={"art-money"}>
        <a href="http://artmoney.com.au"><img src={"/art-money-logo.png"} width="100" /></a>
        <p style={{maxWidth:"600px"}}>Frances Keevil is now offering Art Money:<br />A new, easier way to buy art.
<br />10 payments. 10 months. No interest.<br />Art Money is available from $675 to $50,000.<br />Apply online at <a href="http://artmoney.com.au">www.artmoney.com.au &#x27F6;</a></p>
        </div>
    </div>
  

    </FooterContainer>
    <SubFooterContainer>      <ul>
          <li><Link  to="/privacy">Privacy Policy</Link ></li>
          <li><Link to="/terms">Terms and Conditions</Link ></li>
          
            <li>Copyright © 2021 Frances Keevil</li>
        </ul></SubFooterContainer>
  </div>
  )
}

Footer.defaultProps = {

}

Footer.propTypes = {

}

export default Footer
