import { StaticQuery, graphql, Link } from 'gatsby'
import PropTypes from "prop-types"
import React, {useState}  from "react"

import ContactForm from "./contact-form"
import styled from "styled-components"
import localQuery from "./local-query"

const artists_query = graphql`
  query Artists { 
  artists: craftAPI { entries(type: "artist", siteId:1) {
    ... on CraftAPI_artist_artist_Entry {
      id
      slug
      title
      images {
        ... on CraftAPI_artworks_Asset {
          id
          img
          filename
          url
        }
      }
    }

}}}`

const bgColor = (invert) => invert ? "rgb(60, 230, 200)" : "auto";
const txtColor = (invert) => invert ? "white" : "black";
//const heroF = ({hero}) => hero;
const heroF = ({ hero, siteTitle,invert }) => {
    return !invert ? hero : <h1 style={{ backgroundColor: bgColor(invert), padding: "10vh 10vh"}}>
        <Link
          to="/"
          style={{
            color: txtColor(invert),
      

            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
  }
const homeLink = (home) => home ? <div className={"home-link"}><Link to="/">Home</Link ></div> : <div  className={"home-link"}><Link to="/">Frances Keevil</Link ></div>

const MainMenu =  styled.ul.attrs((props) => ({current:undefined || props.current , active: props.active  }))` 

    position:sticky;
    background-color:white;   
    z-index:10000;
    box-sizing:border-box;
    top:0;
    .home-link {
      text-align:left;
  
      width:50%;
      padding:0 15px;
      a {
      border-bottom: 3px solid rgb(60, 230, 200);
      }
    }
    .menu-button {
      padding:0 15px;
    }
    
    @media (max-width: 600px) {

      &>div { 
      display:flex;
      padding:25px 20px;
      justify-content: space-between;
      width:100%;
      }

        .active {
          bottom:0;
        padding-top:20px;   
        } 
        ul {
              display: ${props => props.active ? "block":"none"};
              padding-top:100px;
              top:40px;left:0px;right:0px;
              bottom:0px;
              margin:0;
              background-color:white;
        }
     }
    left:0;
    right:0;

    background-color:white;
    margin:0;

    text-align:center;
    font-size:16px;

    li {
    list-style-type:none;
     padding:5px;
     margin:0;
    }


   }
   
  @media (min-width: 600px) {
    display:block;
    text-align:left;
    font-size:16px;
    border-bottom:1px solid black;
    .menu-button {
      display:none
    }
    &> div {
      padding:12px 0;
      display:flex;
      justify-content: space-evenly;

      div.home-link {
        
        width:20%;

      }
      &> ul {  
        margin:0;
        display:flex;
        width:100%;
        justify-content:  flex-end; 
        a {
         color:black; text-decoration:none;;
        }

       li {
         padding:5px 25px;
       }
       li a:hover, span:hover {
        cursor:pointer;
        border-bottom: 3px solid rgb(60, 230, 200);
       }
    }
  }
` 

const Submenu =  styled.div.attrs((props) => ({current:undefined || props.current ,name: undefined  || props.name, active: props.current != undefined && props.current == props.name }))` 

    position:fixed;
    left:0;right:0;
    z-index:100000;
    pointer-events: ${props => props.active ? "auto" : "none"};
    top:30px;
    transition: 0.5s all ease;
    opacity: ${props => props.active ? 1 : 0};
    div.sub-container{

      max-height:calc(100vh - 200px);
      padding-bottom:10px;
      overflow-y:auto;
      margin-top:23px;
      background-color:#EAEAEA;

    
}
ul {


  list-style-type:none;
  font-size:18px;
  display:grid;
  grid-template-columns: repeat(12,1fr);
  margin-left: auto; 
  margin-right: auto; 
  max-width: 1400px; 
  width: 100%;
  padding: 35px 0px;
  box-sizing: border-box;
  padding:40px 0px 20px 0px;

  li {
    max-width:300px;
    padding-bottom:0px;
    padding-left:20px;
    grid-column-end: span 3;
      border-bottom:none;
    a:hover, span:hover {
    
     border-bottom: 3px solid rgb(60, 230, 200);
    
    }
    &.highlight {
          grid-column-end: span 12;
      padding-bottom:20px;
    }
  }
}
` 

let sortBySurname = (a, b) => {
    var splitA = a.title.split(" ");
    var splitB = b.title.split(" ");
    var lastA = splitA[splitA.length - 1];
    var lastB = splitB[splitB.length - 1];

    if (lastA < lastB) return -1;
    if (lastA > lastB) return 1;
    return 0;
}

const Header = ({ hero, siteTitle,invert,hideMenu }) => {
  
  const [currentSubmenu, setCurrentSubmenu] = useState(undefined);
  const [menuActive, setMenuActive] = useState(false);
  return(
    <>
    <MainMenu active={menuActive}>
    
      <div>
        {homeLink(invert)}
      <ul>    
        <li  onMouseOver={() =>{console.log("enter"); setCurrentSubmenu("artists")}} onMouseLeave={() =>setCurrentSubmenu(null)}><Link to="/artists">Artists</Link >
          <Submenu onClick={() => {setCurrentSubmenu(null)}} current={currentSubmenu} name="artists">
            <div className="sub-container">

            <ul>
                            <li className="highlight"><Link  to="/artists">View all artists &#x27F6;</Link ></li>
                      <StaticQuery query={artists_query} render={(data) => data.artists.entries.sort(sortBySurname).map((artist) => <li><Link to={"/" + artist.slug}>{artist.title}</Link></li>)} />
            </ul>
            </div>
          </Submenu>
        </li>
      <li><Link to="/artworks">Artworks</Link ></li>
      <li><Link  to="/exhibitions">Exhibitions</Link ></li>
      <li><Link  to="/services">Services</Link ></li>
      <li><Link  to="/about">About</Link ></li>
      <li><ContactForm>Contact</ContactForm></li>
      <li><Link  to="https://www.instagram.com/franceskeevil">Instagram</Link ></li>
      </ul>
      <div className="menu-button" onClick={() => {setMenuActive(!menuActive)}}>Menu</div>
      </div>

  </MainMenu>
    {heroF({ hero, siteTitle,invert })}





  </>
  )
  
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  invert: PropTypes.bool,
  hero: PropTypes.element
}

Header.defaultProps = {
  siteTitle: ``,
  invert: false,
  hero: null
}

export default Header
