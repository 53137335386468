/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useEffect,useState}  from "react"
import PropTypes from "prop-types"
import chroma from "chroma-js"
function Letter({char,offset=0,speed}) {

    
    
    return (
      <React.Fragment>
        <div style={{
            "textAlign": "center",
            "width":"100%",
            position:"absolute",
            top: offset
          }}>{char}</div>
    </React.Fragment>
  )
}
const LetterCol = ({char,colidx,color=0}) => {
  
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [seconds, setSeconds] = useState(1);
  
  function mouseHandler(e){

    setPosition({x: e.clientX, y: e.clientY})
    console.log(position.y)
  }

  useEffect(()=>{
    const interval = setInterval(() => {
      setSeconds(seconds => Math.random());
    }, 1000 );
    return () => clearInterval(interval);
    window.addEventListener('mousemove', mouseHandler)
    return ()=>{
        window.removeEventListener('mousemove', mouseHandler)
      }
    }) 
  let palette = [chroma.scale(["rgb(60, 230, 200)", "rgb(60, 130, 200)"]),chroma.scale(["rgb(230, 230, 200)", "rgb(160, 130, 200)"])]; 
  let scale = palette[color];
  let letters = Array(20).fill("A").map((element,idx) => <Letter char={char} speed={colidx} offset={idx * 100} />)
  return <div style={{ 
    "fontSize":"100px",
    "color":"white",
    "height":"200vh",
    "position":"relative",
    "marginTop": -40,
    "userSelect":"none",
    "cursor":"pointer",
    "backgroundColor":scale(colidx / 14 + seconds / 40).hex(),
    "width":"100%",
    "textAlign": "center",
    "transition": "5s all",
    "transformOrigin":"50vh center",
    "opacity":seconds
  }}>{letters}</div>
}

const Word = ({word}) => <div style={{
  "display":"flex",
      "textAlign": "center",
  "justifyContent":"space-around"
  
}}>{[...word].map((char,idx) => <LetterCol char={char} colidx={idx}/>)}</div>

function Intro({onClick}) {
  return (
  <div onClick={onClick} style={
    {"top":0,
    "bottom":0,
    "left":0,
    "right":0,
    "textAlign": "center",
    "position":"fixed",
    "zIndex":"1000"}}>
    <Word word="Frances Keevil" />
  </div>
  )
}

Intro.defaultProps = {

}

Intro.propTypes = {

}

export default Intro
