/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useState}  from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Intro from "./intro"
import Header from "./header"
import Footer from "./footer"
import "../css/layout.css"

let intro_interval;
const Layout = ({ children,hero,invert,intro }) => {
  const [showIntro, setShowIntro] = useState(true);
  
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  let toggleIntro = function() {
    setShowIntro(!showIntro);
    console.log({showIntro});
    clearInterval(intro_interval);
  }
  let introF = function(show) {
    if(intro && show) {

      return <Intro onClick={toggleIntro} />
    }
  }
  return (
    <>
      <Header hero={hero} hideMenu={false} invert={invert} siteTitle={data.site.siteMetadata?.title || `Title`} />
      {introF(showIntro)}
      <div
        style={{
          justifyContent:"center",
          width:"100%",
          boxSizing:"border-box",
          padding: `0 0rem`,
          display:"flex",
          overflow:"hidden"
        }}>
      <div
        style={{
          margin: `0`,
          maxWidth: 1400,
          width:"100%",
          padding: `0px 0`,
          boxSizing:"border-box",
        }}
      >
            
        <main           style={{
                    marginTop: `2rem`,
                  }}>{children}</main>
              
      </div>
    
      </div>
          <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hero: PropTypes.element,
  invert: PropTypes.bool
}

export default Layout
