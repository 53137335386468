import React, {useState} from "react"
import { Link } from "gatsby"
import Carousel from "./carousel"
import styled from "styled-components"

const StyledForm =  styled.form` 
max-width:1000px;
padding-top:10vh;
margin-left:auto;
margin-right:auto;

input {
  width:48%;
  margin-bottom:20px;
}

button {
      background-color: rgb(60, 230, 200);
      border:none;
      margin:10px;
      display:inline-block;
      width:200px;
      margin-top:20px;
      
}

.actions {
  text-align:center;
}
`
const ContactContainer =  styled.div` 


@keyframes example {
  from {opacity:0;top:-100vh;bottom:100vh;}
  to {opacity:1;top:0vh;bottom:0vh;}
}
opacity: 0;
position:fixed;
z-index:1000;
animation-name: example;
animation-duration: 0.3s;
animation-fill-mode: forwards;
overflow-y:scroll;
background-color:rgba(255,255,255,1);
top:-100vh;left:0vh;bottom:100vh;right:0vh;
`

function ContactForm({children}) {
    const [active, setActive] = useState(false);



    if(active) {
      return <ContactContainer style={{

      }}>
      <div>
        <StyledForm>
            <p style={{
                textAlign:"center",
                borderBottom: "1px solid rgba(0,0,0,0.2)",
                paddingBottom:"20px",
                fontSize:"18px"
              }}>Please fill out the form below <br />or send me an email at frances@franceskeevil.com.au<br /> or call me on +61 411821550</p>
            <input type="text" placeholder="Your Name"></input>
            <input type="text" placeholder="Your E-mail address"></input>
            <textarea style={{
                width:"100%",
                height:"200px",
                padding:"10px",
              
              }}   placeholder="Please write your enquiry here"></textarea>
            <div class="actions"><button onClick={() => {setActive(false)}} style={{backgroundColor:"rgba(0,0,0,0.1)"}}>Cancel</button><button onClick={() => {setActive(false)}}>Submit</button></div>
        </StyledForm>

      </div>
          <div style={{
            opacity: active ? 1 : 0,
            position:"fixed",
            zIndex:1000,
            cursor:"pointer",
            backgroundColor:"rgba(255,255,255,1)",
            top:"20px",right:"30px"
          }} onClick={() => {setActive(false)}}>Go back to website &#x27F6;</div>
      </ContactContainer>
    }else {
      return <span onClick={() => {setActive(true)}}>{children}</span>
    }
}


ContactForm.defaultProps = {

}

ContactForm.propTypes = {

}

export default ContactForm